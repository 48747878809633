/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import Photo from "@material-ui/icons/Photo";
import GroupIcon from "@material-ui/icons/Group";
// import List from "@material-ui/icons/List";
import Message from "@material-ui/icons/Message";
import Money from "@material-ui/icons/Money";
import Warning from "@material-ui/icons/Warning";
import Mail from "@material-ui/icons/Mail";
import CreditCard from "@material-ui/icons/CreditCard";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import Bookmark from "@material-ui/icons/Bookmark";
import Public from "@material-ui/icons/Public";
import TheatersIcon from "@material-ui/icons/Theaters";
import Image from "@material-ui/icons/Image";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import Category from "@material-ui/icons/Category";
import Key from "@material-ui/icons/VpnKey";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import AccountBox from "@material-ui/icons/AccountBox";
import Bolt from "@material-ui/icons/OfflineBolt";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import ShortTextIcon from "@material-ui/icons/ShortText";
import ReceiptIcon from "@material-ui/icons/Receipt";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import Users from "views/Users/Users.js";
import Donations from "views/Donations/Donations.js";
import Reportings from "views/Reportings/Reportings.js";
import Notifications from "views/Notifications/Notifications.js";
import Posts from "views/Posts/Posts.js";
import Bookings from "views/Bookings/Bookings.js";
import Avatars from "views/Avatars/Avatars.js";
// import News from 'views/News/News.js';
import Chat from "views/Chat/Chat.js";
import Transactions from "views/Transactions/Transactions.js";
import Subscriptions from "views/Subscriptions/Subscriptions.js";
import UserMap from "views/UserMap/UserMap.js";
import Videos from "views/Videos/Videos.js";
import Upload from "views/Upload/Upload.js";
import Photos from "views/Photos/Photos.js";
import Tags from "views/Tags/Tags.js";
import ContentCategories from "views/ContentCategories/ContentCategories.js";
import Access from "views/Access/Access.js";
import Identities from "views/Identities/Identities.js";
// import Ambassadors from "views/Ambassadors/Ambassadors.js";
import BankDebits from "views/BankDebits/BankDebits.js";
import Wallets from "views/Wallets/Wallets.js";
import Sponsorship from "views/Sponsorship/Sponsorship";
import Alias from "views/Alias/Alias";
import Invoices from "views/Invoices/Invoices";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    icon: Person,
    component: Users,
    layout: "/admin",
  },
  {
    path: "/alias",
    name: "Alias",
    icon: ShortTextIcon,
    component: Alias,
    layout: "/admin",
  },
  {
    path: "/sponsorship",
    name: "Sponsorship",
    icon: SupervisedUserCircleIcon,
    component: Sponsorship,
    layout: "/admin",
  },
  {
    path: "/posts",
    name: "Posts",
    icon: Photo,
    component: Posts,
    layout: "/admin",
  },
  {
    path: "/bookings",
    name: "Bookings",
    icon: GroupIcon,
    component: Bookings,
    layout: "/admin",
  },
  {
    path: "/avatars",
    name: "Avatars",
    icon: AccountBox,
    component: Avatars,
    layout: "/admin",
  },
  // {
  //   path: "/news",
  //   name: "News",
  //   icon: List,
  //   component: News,
  //   layout: "/admin"
  // },
  {
    path: "/chat",
    name: "Chat",
    icon: Message,
    component: Chat,
    layout: "/admin",
  },
  {
    path: "/map",
    name: "Map",
    icon: Public,
    component: UserMap,
    layout: "/admin",
  },
  {
    path: "/videos",
    name: "Videos",
    icon: TheatersIcon,
    component: Videos,
    layout: "/admin",
  },
  {
    path: "/photos",
    name: "Photos",
    icon: Image,
    component: Photos,
    layout: "/admin",
  },
  // {
  //   path: "/upload",
  //   name: "Upload",
  //   icon: CloudUploadIcon,
  //   component: Upload,
  //   layout: "/admin",
  // },
  {
    path: "/tags",
    name: "Tags",
    icon: LocalOfferIcon,
    component: Tags,
    layout: "/admin",
  },
  {
    path: "/contentCategories",
    name: "ContentCategories",
    icon: Category,
    component: ContentCategories,
    layout: "/admin",
  },
  {
    path: "/access",
    name: "Access",
    icon: Key,
    component: Access,
    layout: "/admin",
  },
  {
    path: "/transactions",
    name: "Transactions",
    icon: AccountBalanceIcon,
    component: Transactions,
    layout: "/admin",
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    icon: Bookmark,
    component: Subscriptions,
    layout: "/admin",
  },
  {
    path: "/identities",
    name: "Identities",
    icon: FingerprintIcon,
    component: Identities,
    layout: "/admin",
  },
  // {
  //   path: "/ambassadors",
  //   name: "Ambassadors",
  //   icon: Person,
  //   component: Ambassadors,
  //   layout: "/admin"
  // },
  {
    path: "/donations",
    name: "Donations",
    icon: Money,
    component: Donations,
    layout: "/admin",
  },
  {
    path: "/reportings",
    name: "Reportings",
    icon: Warning,
    component: Reportings,
    layout: "/admin",
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: Mail,
    component: Notifications,
    layout: "/admin",
  },
  {
    path: "/bankDebits",
    name: "Bank direct debits",
    icon: CreditCard,
    component: BankDebits,
    layout: "/admin",
  },
  {
    path: "/wallets",
    name: "Wallets",
    icon: AccountBalanceWalletIcon,
    component: Wallets,
    layout: "/admin",
  },
  {
    path: "/invoices",
    name: "Invoices",
    icon: ReceiptIcon,
    component: Invoices,
    layout: "/admin",
  },
  // {
  //   path: "/places",
  //   name: "Places",
  //   icon: PlaceIcon,
  //   component: Places,
  //   layout: "/admin"
  // },
  /*{
    path: "/user",
    name: "User Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UserProfile,
    layout: "/admin"
  },
  {
    path: "/table",
    name: "Table List",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    component: TableList,
    layout: "/admin"
  },
  {
    path: "/typography",
    name: "Typography",
    rtlName: "طباعة",
    icon: LibraryBooks,
    component: Typography,
    layout: "/admin"
  },
  {
    path: "/icons",
    name: "Icons",
    rtlName: "الرموز",
    icon: BubbleChart,
    component: Icons,
    layout: "/admin"
  },
  {
    path: "/maps",
    name: "Maps",
    rtlName: "خرائط",
    icon: LocationOn,
    component: Maps,
    layout: "/admin"
  },
  {
    path: "/notifications",
    name: "Notifications",
    rtlName: "إخطارات",
    icon: Notifications,
    component: NotificationsPage,
    layout: "/admin"
  },
  {
    path: "/upgrade-to-pro",
    name: "Upgrade To PRO",
    rtlName: "التطور للاحترافية",
    icon: Unarchive,
    component: UpgradeToPro,
    layout: "/admin"
  }*/
];

export default dashboardRoutes;
